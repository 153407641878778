<template>
  <v-container fluid>
    <h1 class="h1">Log do Sistema</h1>
    <v-card-title>
      <v-row justify="center">
        <v-col>
          <v-data-table :loading="loading" :items="items" :search="search" :headers="headers">
            <template v-slot:top>
              <v-row>
                <v-col cols="3">
                  <v-text-field v-model="searchStartDate" label="Data Início" type="date" class="mx-0" outlined dense clearable ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="searchEndDate" label="Data Fim" type="date" class="mx-0" outlined dense clearable ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="search" label="Pesquisar" class="mx-0" outlined dense clearable append-icon="mdi-magnify" ></v-text-field>
                </v-col>
                <v-col class="text-end" >
                  <v-btn color="red" text class="mr-2" elevation="0" @click="clearLogs"> Limpar </v-btn>
                  <v-btn color="success" elevation="0" :loading="loading" @click="validateAndFetchLogs"> Pesquisar </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.editar`]="{ item }">
              <Editar :currentItem="item" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-title>
  </v-container>
</template>
<script>
import api from '../../http';
import UtilsFunc from '../../service/utilsFunc';
import moment from 'moment';


export default {
  name: "log",
  data: () => UtilsFunc.withCRUDUtils({
    loading: false,
    items: [],
    search: '',
    searchStartDate: '',
    searchEndDate: '',
    headers: [
      { text: "ID", sortable: true, value: "id" },
      { text: "Usuario", sortable: true, value: "usuario" },
      { text: "Data de Modificação", data: true, value: "data" },
      { text: "Onde Modificou", data: true, value: "nome_tabela" },
      { text: "ID da Tabela", data: true, value: "id_table" },
      { text: "Como era antes", sortable: true, value: "estado_anterior" },
      { text: "Após Alteração", sortable: true, value: "estado_atual" },
    ],
  }),
  methods: {
    validateAndFetchLogs() {
      if (!this.searchStartDate || !this.searchEndDate) {
        this.$toast.error('Por favor, preencha as datas de Início e Fim do período para prosseguir com a pesquisa.');
        return;
      }
      this.getLogs();
    },
    async getLogs() {
      this.loading = true;
      try {
        const { data } = await api.get('/log_tables/listar/');
        // console.log(data)
        let filteredData = data;
        // Filtro baseado nas datas de início e fim, se fornecidas
        if (this.searchStartDate && this.searchEndDate) {
          const startDate = moment(this.searchStartDate, 'YYYY-MM-DD');
          const endDate = moment(this.searchEndDate, 'YYYY-MM-DD').endOf('day');

          filteredData = filteredData.filter(item => {
            const itemDate = moment(item.data, 'YYYY-MM-DD HH:mm:ss');
            return itemDate.isBetween(startDate, endDate, undefined, '[]');
          });
          
        }

        // Mapeamento e formatação da data para exibição
        this.items = filteredData
          .sort((a, b) => b.id - a.id)
          .map(item => {
            // Verifica se o item corresponde aos critérios especificados
            if (item.nome_tabela === "Endereço Paciente" && item.estado_anterior === "Status: 1") {
              item.estado_anterior = "Status: Ativo";
            }
            if (item.nome_tabela === "Endereço Paciente" && item.estado_anterior === "Status: 0") {
              item.estado_anterior = "Status: Inativo";
            }
            if (item.nome_tabela === "Endereço Paciente" && item.estado_atual=== "Status: 1") {
              item.estado_atual= "Status: Ativo";
            }
            if (item.nome_tabela === "Endereço Paciente" && item.estado_atual === "Status: 0") {
              item.estado_atual = "Status: Inativo";
            }

            // Formata a data
            return {
              ...item,
              data: moment(item.data, 'YYYY-MM-DD HH:mm:ss').subtract(3, 'hours').format('DD/MM/YYYY [às] HH:mm:ss')
            };
          });
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    clearLogs() {
      this.items = [];
      this.search = '';
      this.searchStartDate = '';
      this.searchEndDate = '';
    },
  },
}
</script>
